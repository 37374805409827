import {
	DictionaryMap,
	Dictionary,
	DictionaryReducer,
	QueryResponse,
} from "modules/types/dictionary";
import { PayloadAction } from "@reduxjs/toolkit";

/**
 * DICTIONARY STATE
 */

export const resetDictionaries = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary[]>
): DictionaryReducer => {
	let map: DictionaryMap = {};
	for (let dictionary of action.payload) map[dictionary?._id] = dictionary;
	return {
		...state,
		map,
		init: true,
	};
};

export const setDictionaries = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary[]>
): DictionaryReducer => {
	let map: DictionaryMap = { ...state.map };
	for (let dictionary of action.payload)
		map[dictionary?._id] = { ...map[dictionary?._id], ...dictionary };
	return {
		...state,
		map,
		init: true,
	};
};

export const addDictionary = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary>
) => {
	let dictionariesMap: DictionaryMap = { ...state.map };
	dictionariesMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: dictionariesMap,
	};
};

export const updateDictionary = (
	state: DictionaryReducer,
	action: PayloadAction<Dictionary>
) => {
	let dictionariesMap: DictionaryMap = { ...state.map };
	dictionariesMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: dictionariesMap,
	};
};

export const deleteDictionary = (
	state: DictionaryReducer,
	action: PayloadAction<{ dictionaryId: string }>
) => {
	let dictionariesMap: DictionaryMap = { ...state.map };
	if (dictionariesMap[action.payload.dictionaryId])
		delete dictionariesMap[action.payload.dictionaryId];

	return {
		...state,
		map: dictionariesMap,
	};
};

/**
 * WORDS STATE
 */

export const addDictionaryWord = (
	state: DictionaryReducer,
	action: PayloadAction<string>
) => {
	let { dictionaryWords, words } = state;

	if (dictionaryWords.indexOf(action.payload) === -1)
		dictionaryWords.unshift(action.payload);

	let index = words.indexOf(action.payload);
	if (index !== -1) words.splice(index, 1);

	return {
		...state,
		dictionaryWords,
		words,
	};
};

export const setDictionaryWords = (
	state: DictionaryReducer,
	action: PayloadAction<string[]>
) => {
	let dictionaryWords = [...state.dictionaryWords];
	let words = [...state.words];
	dictionaryWords = action.payload;
	words = words.filter((word) => !action.payload.includes(word));

	// action.payload.forEach((word) => {
	// if (dictionaryWords.indexOf(word) === -1) dictionaryWords.unshift(word);
	// const index = words.indexOf(word);
	// if (index !== -1) words.splice(index, 1);
	// });

	return {
		...state,
		dictionaryWords,
		words,
	};
};

export const setWords = (
	state: DictionaryReducer,
	action: PayloadAction<string[]>
) => {
	let words = [...state.words];
	words = action.payload;
	// action.payload.forEach((word) => {
	// 	if (words.indexOf(word) === -1) words.unshift(word);
	// });

	return {
		...state,
		words,
	};
};

export const setSynonyms = (
	state: DictionaryReducer,
	action: PayloadAction<string[]>
) => {
	let synonyms = [...state.synonyms];
	let words = [...state.words];
	synonyms = action.payload;
	words = words.filter((word) => !action.payload.includes(word));

	// action.payload.forEach((word) => {
	// 	if (synonyms.indexOf(word) === -1) synonyms.unshift(word);
	// 	let index = words.indexOf(word);
	// 	if (index !== -1) words.splice(index, 1);
	// });

	return {
		...state,
		synonyms,
		words,
	};
};

export const deleteDictionaryWord = (
	state: DictionaryReducer,
	action: PayloadAction<string>
) => {
	let { dictionaryWords } = state;
	let index = dictionaryWords.indexOf(action.payload);
	if (index !== -1) dictionaryWords.splice(index, 1);
	return {
		...state,
		dictionaryWords,
	};
};

export const deleteSynonym = (
	state: DictionaryReducer,
	action: PayloadAction<string>
) => {
	let { synonyms } = state;
	let index = synonyms.indexOf(action.payload);
	if (index !== -1) synonyms.splice(index, 1);
	return {
		...state,
		synonyms,
	};
};

export const clearDictionaryWords = (state: DictionaryReducer) => {
	return {
		...state,
		dictionaryWords: [],
	};
};

export const setCount = (
	state: DictionaryReducer,
	action: PayloadAction<number>
) => {
	let { count } = state;
	count = action.payload;
	return {
		...state,
		count,
	};
};

/**
 * @param state
 * @param action
 * @returns
 */
export const setSearchQuery = (
	state: DictionaryReducer,
	action: PayloadAction<QueryResponse>
) => {
	if (!action.payload.words) action.payload.words = [];
	if (!action.payload.customWords) action.payload.customWords = [];
	if (!action.payload.synonyms) action.payload.synonyms = [];
	if (!action.payload.count) action.payload.count = 0;

	return {
		...state,
		count: action.payload.count,
		words: action.payload.words,
		dictionaryWords: action.payload.customWords,
		synonyms: action.payload.synonyms,
	};
};
