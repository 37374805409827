import { ResourceRealType, ResourceType } from "modules/types/global";
import { Group, GroupReducer, Member } from "modules/types/group";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatKnowledgeBeforeRedux } from "modules/utils/ontology";
import { UserType } from "modules/types/user";

export const resetGroups = (
	state: GroupReducer,
	action: PayloadAction<Group[]>
): GroupReducer => {
	let groups = formatKnowledgeBeforeRedux(action.payload) as Group[];
	let map: Record<string, Group> = {};
	groups.forEach((group: Group) => {
		map[group?._id] = group;
	});

	return {
		...state,
		map,
		init: true,
	};
};

export const setGroups = (
	state: GroupReducer,
	action: PayloadAction<Group[]>
): GroupReducer => {
	let groups = formatKnowledgeBeforeRedux(action.payload) as Group[];
	let map: Record<string, Group> = { ...state.map };
	groups.forEach((group: Group) => {
		map[group?._id] = { ...map[group?._id], ...group };
	});

	return {
		...state,
		map,
		init: true,
	};
};

export const addGroup = (state: GroupReducer, action: PayloadAction<Group>) => {
	let groups = formatKnowledgeBeforeRedux([action.payload]) as Group[];
	let groupsMap: Record<string, Group> = { ...state.map };
	groupsMap[action.payload?._id] = groups[0];
	return {
		...state,
		map: groupsMap,
	};
};

export const updateGroup = (
	state: GroupReducer,
	action: PayloadAction<Group>
) => {
	let groups = formatKnowledgeBeforeRedux([action.payload]) as Group[];
	let map: Record<string, Group> = { ...state.map };
	map[groups[0]?._id] = groups[0];

	return {
		...state,
		map,
	};
};

export const deleteGroup = (
	state: GroupReducer,
	action: PayloadAction<{ groupId: string }>
) => {
	let groupsMap: Record<string, Group> = { ...state.map };
	if (groupsMap[action.payload.groupId])
		delete groupsMap[action.payload.groupId];

	return {
		...state,
		map: groupsMap,
	};
};

export const setGroupMembers = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		members: Member[];
		reset: boolean;
	}>
) => {
	const { groupId, members, reset } = action.payload;

	let memberMap = { ...state.members };

	if (reset) memberMap[groupId] = [...members];
	else {
		const obj: Record<string, Member> = {
			...memberMap[groupId].reduce((acc, member) => {
				acc[member._id] = member;
				return acc;
			}, {} as Record<string, Member>),
			...members.reduce((acc, member) => {
				acc[member._id] = member;
				return acc;
			}, {} as Record<string, Member>),
		};
		memberMap[groupId] = [...Object.values(obj)];
	}

	return {
		...state,
		members: memberMap,
	};
};

export const selectGroup = (
	state: GroupReducer,
	action: PayloadAction<string>
) => {
	if (action.payload in state.map)
		return {
			...state,
			group: { ...state.map[action.payload] },
		};
	else if (Object.keys(state.map)?.length)
		return {
			...state,
			group: { ...state.map[Object.keys(state.map)[0]] },
		};
	return {
		...state,
	};
};

/**
 * TODO: UPDATE MEMBER.ROLE
 * ! MODIFICATION NOT TAKEN IN COUNT
 * @param state
 * @param action
 * @returns
 */
export const manageRole = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		member: Member;
		userId: string;
		role: UserType;
	}>
) => {
	const members = { ...state.members[action.payload.groupId] };
	let member = members.find(
		(member) => member._id === action.payload.userId
	) as Member;
	member.role = action.payload.role;

	return {
		...state,
	};
};

export const addResourceToGroup = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		groupKey: ResourceType;
		resourceId: string;
	}>
) => {
	const { groupId, groupKey, resourceId } = action.payload;
	let groupsMap = { ...state.map };

	if (groupId && groupId in groupsMap) {
		switch (groupKey) {
			case "project":
				groupsMap[groupId].projectIds.push(resourceId);
				break;
			case "dictionary":
				groupsMap[groupId].dictionaryIds.push(resourceId);
				break;
			case "annotation-campaign":
				groupsMap[groupId].annotationCampaignIds.push(resourceId);
				break;
			case "ontology":
				groupsMap[groupId].ontologyIds.push(resourceId);
				break;
			case "pattern":
				groupsMap[groupId].patternIds.push(resourceId);
				break;
		}
	}

	return {
		...state,
		map: groupsMap,
	};
};

export const deleteResourceFromGroup = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		groupKey: ResourceType;
		resource: ResourceRealType;
	}>
) => {
	const { groupId, groupKey, resource } = action.payload;
	let groupsMap = { ...state.map };

	if (groupId && groupId in groupsMap) {
		switch (groupKey) {
			case "project":
				groupsMap[groupId].projectIds = groupsMap[
					groupId
				].projectIds.filter((_id: string) => _id !== resource._id);
				break;
			case "dictionary":
				groupsMap[groupId].dictionaryIds = groupsMap[
					groupId
				].dictionaryIds.filter((_id: string) => _id !== resource._id);
				break;
			case "annotation-campaign":
				groupsMap[groupId].annotationCampaignIds = groupsMap[
					groupId
				].annotationCampaignIds.filter(
					(_id: string) => _id !== resource._id
				);
				break;
			case "ontology":
				groupsMap[groupId].ontologyIds = groupsMap[
					groupId
				].ontologyIds.filter((_id: string) => _id !== resource._id);
				break;
			case "pattern":
				groupsMap[groupId].patternIds = groupsMap[
					groupId
				].patternIds.filter((_id: string) => _id !== resource._id);
				break;
		}
	}

	return {
		...state,
		map: groupsMap,
	};
};

export const deleteGroupMember = (
	state: GroupReducer,
	action: PayloadAction<{
		groupId: string;
		memberId: string;
	}>
) => {
	const { groupId, memberId } = action.payload;
	let map = { ...state.map };
	let members = { ...state.members };

	members[groupId] = members[groupId].filter((member: Member) => {
		return member._id !== memberId;
	});

	map[groupId].userIds = map[groupId].userIds.filter((userId: string) => {
		return userId !== memberId;
	});

	return {
		...state,
		members,
		map,
	};
};
