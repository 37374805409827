import { publishManyInGroupApi, unpublishManyInGroupApi } from "./group.api";
import {
	BodyElement,
	EditableType,
	PageParams,
	ReadResponse,
	ResourceType,
} from "modules/types/global";
import { updateResource } from "modules/utils/store";
import {
	setCampaigns,
	updateCampaign,
} from "store/annotation-campaign/annotation-campaign-actions";
import { setDatasets, updateDataset } from "store/dataset/dataset-actions";
import {
	setDictionaries,
	updateDictionary,
} from "store/dictionary/dictionary-actions";
import {
	deleteGroup,
	setGroupMembers,
	setGroups,
	updateGroup,
} from "store/group/group-actions";
import { setOntologies, updateOntology } from "store/ontology/actions";
import { setPatterns, updatePattern } from "store/pattern/pattern-actions";
import {
	updateProject,
	updateProjectsGroups,
} from "store/project/project-actions";
import { setUser, updateUserGroups } from "store/user/user-actions";
import { Project } from "modules/types/project";
import { Campaign } from "modules/types/annotation-campaign";
import { Group } from "modules/types/group";

import { setProjects } from "store/project/project-actions";
import { getAllResourceMaps, getResourceGroups } from "store/hooks";

import * as api from "./group.api";
import { convertUserToMember } from "modules/utils";
import { UserType } from "modules/types";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { AuthService } from "views/public/auth/services/auth.service";
import { updateNotificationStatus } from "store/notification/notification-actions";

// ---------------------- CRUD GROUP MANAGEMENT ----------------------

export const createGroupStore = async (group: EditableType<Group>) => {
	const response = await api.createGroupApi(group);
	if (response.group && response.user) {
		updateUserGroups(response.user);
		setGroups([response.group]);
		return response.group;
	}
};

export const readGroupStore = async (
	urlParams: PageParams,
	body: BodyElement
): Promise<ReadResponse> => {
	const response = await api.readGroupApi(urlParams, body);
	if (response.documents) setGroups(response.documents as Group[]);
	return response;
};

export const updateGroupStore = async (
	groupId: string,
	group: EditableType<Group>
) => {
	const response = await api.updateGroupApi(groupId, group);
	if (response.group) {
		setGroups([response.group]);
		return response.group;
	}
};

export const deleteGroupStore = async (group: Group) => {
	const response = await api.deleteGroupApi(group._id);
	if (response.user && response.projects) {
		deleteGroup(group._id);
		updateUserGroups(response.user);
		updateProjectsGroups(response.projects);
		return true;
	}
	return false;
};

// ---------------------- SHARE RESOURCE IN GROUP MANAGEMENT ----------------------

/**
 * Add resources to a group
 * @param groupId
 * @param resources
 * @returns
 */
export const addResourcesToGroupStore = async (
	groupId: string,
	resourceType: ResourceType,
	resourceIds: string[]
) => {
	const response = await api.addResourcesToGroupApi(
		groupId,
		resourceType,
		resourceIds
	);
	if (response.resources) {
		setResourcesStore({
			[resourceType]: response.resources,
		} as Record<ResourceType, any[]>);
		return response.resources;
	}
	return false;
};

/**
 * Add resources to project groups
 * @param projectId
 * @param resources
 * @returns
 */
export const addResourcesToProjectGroupsStore = async (
	projectId: string,
	resourceType: ResourceType,
	resourceIds: string[]
) => {
	const { projectMap } = getAllResourceMaps();
	const groupMap = getResourceGroups(projectMap[projectId]?.owners || []);
	const projectGroupIds = (projectMap[projectId]?.owners || []).filter(
		(owner) =>
			owner in groupMap ? groupMap[owner]?.groupType !== "User" : false
	);
	return (
		await Promise.all(
			projectGroupIds.map(async (groupId) => {
				try {
					const response = await addResourcesToGroupStore(
						groupId,
						resourceType,
						resourceIds
					);
					return response || [];
				} catch (error) {
					console.error(error);
					return [];
				}
			})
		)
	).flat();
};

/**
 * Remove resources from a group
 * @param groupId
 * @param resources
 * @returns
 */
export const removeResourcesFromGroupStore = async (
	groupId: string,
	resourceType: ResourceType,
	resourceId: string[]
) => {
	const response = await api.removeResourcesFromGroupApi(
		groupId,
		resourceType,
		resourceId
	);
	if (response.resources) {
		setResourcesStore({
			[resourceType]: response.resources,
		} as Record<ResourceType, any[]>);
		return response.resources;
	}
	return false;
};

/**
 * Remove resources from project groups
 * @param projectId
 * @param resources
 * @returns
 */
export const removeResourcesFromProjectGroupsStore = async (
	projectId: string,
	resourceType: ResourceType,
	resourceIds: string[]
) => {
	const { projectMap } = getAllResourceMaps();
	const groupMap = getResourceGroups(projectMap[projectId]?.owners || []);
	const projectGroupIds = (projectMap[projectId]?.owners || []).filter(
		(owner) =>
			owner in groupMap ? groupMap[owner]?.groupType !== "User" : false
	);
	return (
		await Promise.all(
			projectGroupIds.map(async (groupId) => {
				try {
					const response = await removeResourcesFromGroupStore(
						groupId,
						resourceType,
						resourceIds
					);
					return response || [];
				} catch (error) {
					console.error(error);
					return [];
				}
			})
		)
	).flat();
};

// ---------------------- MEMBER MANAGEMENT ----------------------

export const addUsersToGroupStore = async (
	groupId: string,
	usersToBeModified: {
		email: string;
		role: string;
	}[]
) => {
	const response = await api.addUsersToGroupApi(groupId, usersToBeModified);
	if (response) {
		return response;
	}
	return false;
};

export const listUsersInGroupStore = async (groupIds: string[]) =>
	(
		await Promise.all(
			groupIds.map(async (groupId) => {
				const response = await api.listUsersInGroupApi(groupId);
				if (response.length) {
					setGroupMembers(groupId, response);
					return response;
				}
				return [];
			})
		)
	).flat();

export const changeUsersRoleInGroupStore = async (
	groupId: string,
	userIdsToBeModified: {
		id: string;
		role: string;
	}[]
) => {
	const response = await api.changeUsersRoleInGroupApi(
		groupId,
		userIdsToBeModified
	);
	if (response.members) {
		setGroupMembers(groupId, response.members, false);
		if (response.user) {
			setUser(response.user);
			return response.members.concat(convertUserToMember(response.user));
		}
		return response.members;
	}
	return false;
};

export const requestRolePromotionStore = async (
	groupId: string,
	role: UserType
): Promise<boolean> => {
	const response = await api.requestRolePromotionApi(groupId, role);
	if (response.message === "Role promotion request sent.") return true;
	return false;
};

export const removeUsersFromGroupStore = async (
	groupId: string,
	userIdsToBeModified: string[]
) => {
	const reponse = await api.removeUsersFromGroupApi(
		groupId,
		userIdsToBeModified
	);
	if (reponse) {
		if (reponse.group) setGroups([reponse.group]);
		if (reponse.members) setGroupMembers(groupId, reponse.members);
		return reponse;
	}
	return false;
};

// -------------------------------------------------------------------
/**
 * Map resources by type
 * @param resources
 * @returns
 */
export const mapResourcesByType = (
	resources: {
		resource: any;
		resourceType: ResourceType;
	}[]
) => {
	return resources.reduce((acc, curr) => {
		if (!acc[curr.resourceType]) acc[curr.resourceType] = [];
		return {
			...acc,
			[curr.resourceType]: [...acc[curr.resourceType], curr.resource],
		};
	}, {} as Record<ResourceType, any[]>);
};

/**
 * Set resources in the store
 * @param map
 */
export const setResourcesStore = (map: Record<ResourceType, any[]>) => {
	Object.keys(map).forEach((resourceType) => {
		const key = resourceType as ResourceType;
		switch (key) {
			case "annotation-campaign":
				setCampaigns(map[key]);
				break;
			case "project":
				setProjects(map[key]);
				break;
			case "dictionary":
				setDictionaries(map[key]);
				break;
			case "pattern":
				setPatterns(map[key]);
				break;
			case "dataset":
				setDatasets(map[key]);
				break;
			case "ontology":
				setOntologies(map[key]);
				break;
		}
	});
};

// ---------------------- PUBLISH GROUP MANAGEMENT (deprecated) ----------------------

/**
 * @deprecated
 */
export const publishInGroupStore = async (
	resourceId: string,
	groupId: string,
	resourceType: ResourceType
) => {
	const result = await publishManyInGroupApi(
		generateResourceIds(resourceType, resourceId),
		groupId
	);
	Object.keys(result).forEach((key) => {
		if (key === "group" || key === "user")
			updateResIds(groupId, key, result[key]);
		else
			result[key].forEach((res: any) => {
				updateResIds(groupId, key, res);
			});
	});
	return result;
};

/**
 * @deprecated
 */
export const publishProjectInGroupStore = async (
	resources: { [key: string]: string[] },
	groupId: string
) => {
	const result = await publishManyInGroupApi(resources, groupId);
	Object.keys(result).forEach((key) => {
		if (key === "group" || key === "user")
			updateResIds(groupId, key, result[key]);
		else
			result[key].forEach((res: any) => {
				updateResIds(groupId, key, res);
			});
	});
	return result;
};

/**
 * @deprecated
 */
export const unpublishInGroupStore = async (
	resourceId: string,
	groupId: string,
	resourceType: ResourceType
) => {
	const result = await unpublishManyInGroupApi(
		generateResourceIds(resourceType, resourceId),
		groupId
	);
	Object.keys(result).forEach((key) => {
		if (key === "group" || key === "user")
			updateResIds(groupId, key, result[key]);
		else
			result[key].forEach((res: any) => {
				updateResIds(groupId, key, res);
			});
	});
};

/**
 * @deprecated
 */
export const unpublishProjectInGroupStore = async (
	project: Project,
	campaigns: Campaign[],
	groupId: string
) => {
	let resources: { [key: string]: string[] } = {};

	resources["projectIds"] = [project?._id];
	resources["dictionaryIds"] = project.dictionaryIds;
	resources["knowledgeIds"] = project.ontologyIds;
	resources["patternIds"] = project.patternIds;
	resources["annotationCampaignIds"] = campaigns?.map((_c) => _c?._id);

	const result = await unpublishManyInGroupApi(resources, groupId);
	Object.keys(result).forEach((key) => {
		if (key === "group" || key === "user")
			updateResIds(groupId, key, result[key]);
		else
			result[key].forEach((res: any) => {
				updateResIds(groupId, key, res);
			});
	});
	return result;
};

/**
 * Choose the resource to update
 */
export const updateResourceStore = (
	resourceType: ResourceType,
	result: any
) => {
	switch (resourceType) {
		case "annotation-campaign":
			updateResource(
				resourceType,
				result.annotationCampaign,
				result.group
			);
			return result;
		case "project":
			updateResource(resourceType, result.project, result.group);
			return result;
		case "dictionary":
			updateResource(resourceType, result.dictionary, result.group);
			return result;
		case "pattern":
			updateResource(resourceType, result.pattern, result.group);
			return result;
		case "dataset":
			updateResource(resourceType, result.group, result.group);
			return result;
		case "ontology":
			updateResource(resourceType, result.knowledge, result.group);
			return result;
		case "group":
			updateResource(resourceType, result.group);
			return result;
	}
	return false;
};

/**
 * Choose the resource to update
 */
export const updateResIds = (
	groupId: string,
	resourceType: string,
	resource: any
) => {
	switch (resourceType) {
		case "annotationCampaigns":
			updateCampaign(resource);
			break;
		case "projects":
			updateProject(resource);
			break;
		case "dictionaries":
			updateDictionary(resource);
			break;
		case "patterns":
			updatePattern(resource);
			break;
		case "groups":
			updateDataset(resource);
			break;
		case "knowledges":
			updateOntology(resource);
			break;
		case "group":
			updateGroup(resource);
			break;
		case "user":
			setUser(resource);
			break;
	}
};

export const generateResourceIds = (
	resourceType: ResourceType,
	resourceId: string
) => {
	let resourcesIds: { [key: string]: string[] } = {};
	switch (resourceType) {
		case "annotation-campaign":
			resourcesIds["annotationCampaignIds"] = [resourceId];
			return resourcesIds;
		case "dictionary":
			resourcesIds["dictionaryIds"] = [resourceId];
			return resourcesIds;
		case "pattern":
			resourcesIds["patternIds"] = [resourceId];
			return resourcesIds;
		case "dataset":
			resourcesIds["groupIds"] = [resourceId];
			return resourcesIds;
		case "ontology":
			resourcesIds["knowledgeIds"] = [resourceId];
			return resourcesIds;
	}
	return resourcesIds;
};

// ---------------------- NOTIFICATION GROUP MANAGEMENT ----------------------

export const acceptInvitationGroupStore = async (
	notificationId: string,
	invitationId: string,
	accepted: boolean
) => {
	let status: "COMPLETED" | "REFUSED" = "COMPLETED";

	const response = await api.acceptInvitationGroupApi(invitationId, accepted);

	if (response?.data) {
		setGroups([response.data]);
		new RetrieveDataService().retrieveMembers(response.data._id);
		await new AuthService().getSelf();
	} else if (response?.message === "Invitation rejected") {
		status = "REFUSED";
	}
	updateNotificationStatus(notificationId, status);
	return response;
};

export const acceptRolePromotionRequestStore = async (
	notificationId: string,
	requestId: string,
	accepted: boolean
): Promise<{ data?: Group; message: string }> => {
	let status: "COMPLETED" | "REFUSED" = "COMPLETED";

	const response = await api.acceptRolePromotionRequestApi(
		requestId,
		accepted
	);

	if (response?.data) {
		setGroups([response.data]);
		new RetrieveDataService().retrieveMembers(response.data._id);
		await new AuthService().getSelf();
	} else if (response?.message === "Role promotion request rejected") {
		status = "REFUSED";
	}
	updateNotificationStatus(notificationId, status);
	return response;
};
