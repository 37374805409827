import { Group, Member } from "modules/types/group";
import { UserData, UserType } from "modules/types/user";
import { Abilities } from "store/hooks";

export const getRoleGroup = (
	user: UserData,
	groupId: string,
	membersMap: Record<string, Member[]>
) => {
	const members = membersMap[groupId];
	if (members) {
		const member = members.find((member) => member._id === user._id);
		if (member) return member.role;
	}
	return "User";
};

export const formatUserTypeToCampaignType = (userType: UserType) => {
	switch (userType) {
		case "Admin":
			return "SuperAnnotator";
		case "SuperUser":
			return "SuperAnnotator";
		case "SuperAnnotator":
			return "SuperAnnotator";
		case "Annotator":
			return "Annotator";
		case "User":
			return "Annotator";
	}
};

export const convertUserToMember = (
	user: UserData,
	groupId?: string
): Member => {
	return {
		_id: user._id,
		email: user.email,
		role: user.groupIds.find((g) => g.id === groupId)?.role || "User",
		name: user.username,
		profile: user.profile,
	};
};

export const canUpdateMemberRole = (
	abilities: Abilities,
	member: Member,
	group: Group
) => {
	switch (abilities.role) {
		case "Admin":
			return member._id !== group.author;
		case "SuperUser":
			return [
				"SuperUser",
				"SuperAnnotator",
				"Annotator",
				"User",
			].includes(member.role);
		case "SuperAnnotator":
			return ["SuperAnnotator", "Annotator", "User"].includes(
				member.role
			);
		// TODO: check if this is correct
		case "Annotator":
			return ["Annotator", "User"].includes(member.role);
		// TODO: check if this is correct
		case "User":
			return ["Annotator", "User"].includes(member.role);
		default:
			return false;
	}
};
