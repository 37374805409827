import {
	DatasetMap,
	Dataset,
	DatasetReducer,
	AssetMap,
	Asset,
} from "modules/types/dataset";
import { PayloadAction } from "@reduxjs/toolkit";

/************************************************************************************************
 * DATASETS METHODS
 ************************************************************************************************/
export const resetDatasets = (
	state: DatasetReducer,
	action: PayloadAction<Dataset[]>
): DatasetReducer => {
	let datasetMap: DatasetMap = {};
	for (let dataset of action.payload) datasetMap[dataset._id] = dataset;
	return {
		...state,
		datasetMap,
		init: true,
	};
};

export const setDatasets = (
	state: DatasetReducer,
	action: PayloadAction<Dataset[]>
): DatasetReducer => {
	let datasetMap: DatasetMap = { ...state.datasetMap };
	for (let dataset of action.payload)
		datasetMap[dataset._id] = { ...datasetMap[dataset._id], ...dataset };

	return {
		...state,
		datasetMap,
		init: true,
	};
};

export const addDataset = (
	state: DatasetReducer,
	action: PayloadAction<Dataset>
) => {
	let datasetMap: DatasetMap = { ...state.datasetMap };
	datasetMap[action.payload._id] = action.payload;

	return {
		...state,
		datasetMap,
	};
};

export const updateDataset = (
	state: DatasetReducer,
	action: PayloadAction<Dataset>
) => {
	let datasetMap: DatasetMap = { ...state.datasetMap };
	datasetMap[action.payload._id] = action.payload;
	return {
		...state,
		datasetMap,
	};
};

export const deleteDataset = (
	state: DatasetReducer,
	action: PayloadAction<{ datasetId: string }>
) => {
	let datasetMap: DatasetMap = { ...state.datasetMap };
	if (datasetMap[action.payload.datasetId])
		delete datasetMap[action.payload.datasetId];

	return {
		...state,
		datasetMap,
	};
};

/************************************************************************************************
 * ASSETS METHODS
 ************************************************************************************************/
export const resetAssets = (
	state: DatasetReducer,
	action: PayloadAction<Asset[]>
): DatasetReducer => {
	let assetMap: AssetMap = {};
	for (let asset of action.payload) assetMap[asset._id] = asset;
	return {
		...state,
		assetMap,
		init: true,
	};
};

export const setAssets = (
	state: DatasetReducer,
	action: PayloadAction<Asset[]>
): DatasetReducer => {
	let assetMap: AssetMap = { ...state.assetMap };
	for (let asset of action.payload)
		assetMap[asset._id] = { ...assetMap[asset._id], ...asset };

	return {
		...state,
		assetMap,
		init: true,
	};
};

export const deleteAssets = (
	state: DatasetReducer,
	action: PayloadAction<{ datasets: Dataset[]; assetIds: string[] }>
) => {
	let assetMap: AssetMap = { ...state.assetMap };
	let datasetMap: DatasetMap = { ...state.datasetMap };

	action.payload.datasets.forEach(
		(dataset) => (datasetMap[dataset._id] = dataset)
	);
	action.payload.assetIds.forEach((assetId) => delete assetMap[assetId]);

	return {
		...state,
		datasetMap,
		assetMap,
	};
};
