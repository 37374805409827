import { UserReducer, UserData, GroupItem } from "modules/types/user";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatKnowledgeBeforeRedux } from "modules/utils/ontology";

export const setUser = (
	state: UserReducer,
	action: PayloadAction<UserData>
): UserReducer => {
	let data = formatKnowledgeBeforeRedux(action.payload) as UserData;
	return {
		...state,
		data,
		loggedIn: true,
		init: true,
	};
};

export const updateUserGroups = (
	state: UserReducer,
	action: PayloadAction<{
		userData: {
			_id: string;
			groupIds: GroupItem[];
		};
	}>
): UserReducer => {
	const { userData } = action.payload;

	return {
		...state,
		data: state.data
			? {
					...state.data,
					groupIds: userData.groupIds,
			  }
			: null,
	};
};

export const deleteUser = (
	state: UserReducer,
	action: PayloadAction<UserData>
): UserReducer => {
	return {
		...state,
		data: null,
		loggedIn: false,
	};
};

export const setLoggedIn = (
	state: UserReducer,
	action: PayloadAction<boolean>
): UserReducer => {
	return {
		...state,
		loggedIn: action.payload,
		init: true,
	};
};
