import { OntologyMap, Ontology, OntologyReducer } from "modules/types/ontology";
import { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { formatOntologies } from "modules/utils/ontology";

export const resetOntologies = (
	state: OntologyReducer,
	action: PayloadAction<Ontology[]>
): OntologyReducer => {
	let ontologyMap: OntologyMap = {};

	for (let ontology of formatOntologies(action.payload, ontologyMap))
		ontologyMap[ontology?._id] = ontology;

	return {
		...state,
		ontologyMap,
		init: true,
	};
};

export const setOntologies = (
	state: OntologyReducer,
	action: PayloadAction<Ontology[]>
): OntologyReducer => {
	let ontologyMap: OntologyMap = JSON.parse(
		JSON.stringify(state.ontologyMap)
	);

	for (let ontology of formatOntologies(action.payload, ontologyMap))
		ontologyMap[ontology?._id] = {
			...ontologyMap[ontology?._id],
			...ontology,
		};

	return {
		...state,
		ontologyMap,
		init: true,
	};
};

export const setProjectOntologies = (
	state: OntologyReducer,
	action: PayloadAction<Ontology[]>
): OntologyReducer => {
	let ontologyMap: OntologyMap = JSON.parse(
		JSON.stringify(state.ontologyMap)
	);

	for (let ontology of formatOntologies(action.payload, ontologyMap))
		ontologyMap[ontology?._id] = {
			...ontologyMap[ontology?._id],
			...ontology,
		};

	return {
		...state,
		ontologyMap,
	};
};

export const addOntology = (
	state: OntologyReducer,
	action: PayloadAction<Ontology>
) => {
	let ontologyMap: OntologyMap = JSON.parse(
		JSON.stringify(state.ontologyMap)
	);

	ontologyMap[action.payload?._id] = formatOntologies(
		[action.payload],
		ontologyMap
	)[0];

	return {
		...state,
		ontologyMap,
	};
};

export const updateOntology = (
	state: OntologyReducer,
	action: PayloadAction<Ontology>
) => {
	let ontologyMap: OntologyMap = JSON.parse(
		JSON.stringify(state.ontologyMap)
	);

	ontologyMap[action.payload?._id] = {
		...ontologyMap[action.payload?._id],
		...formatOntologies([action.payload], ontologyMap)[0],
	};

	return {
		...state,
		ontologyMap,
	};
};

export const updateOntologyDiff = (
	state: OntologyReducer,
	action: AnyAction
) => {
	let ontologyMap: OntologyMap = { ...state.ontologyMap };
	ontologyMap[action.payload.ontology?._id] = {
		...ontologyMap[action.payload.ontology?._id],
		...action.payload.diff,
	};

	return {
		...state,
		ontologyMap,
	};
};

export const deleteOntology = (
	state: OntologyReducer,
	action: PayloadAction<{ ontologyId: string }>
) => {
	let _ontologyMap: OntologyMap = { ...state.ontologyMap };
	if (_ontologyMap[action.payload.ontologyId])
		delete _ontologyMap[action.payload.ontologyId];

	return {
		...state,
		ontologyMap: _ontologyMap,
	};
};
