import {
	EditableType,
	PageParams,
	BodyElement,
	ReadResponse,
	ResourceType,
} from "modules/types/global";
import { Group, Member } from "modules/types/group";
import { Ontology } from "modules/types/ontology";
import { GroupItem, UserData, UserType } from "modules/types/user";
import { formatRoute } from "modules/utils/resource";
import { RequestService as api } from "services/request.service";

// ---------------------- CRUD GROUP MANAGEMENT ----------------------

export const createGroupApi = async (
	group: EditableType<Group>
): Promise<{
	group: Group;
	user: {
		_id: string;
		groupIds: GroupItem[];
	};
}> =>
	await new api().request("group/create", "POST", {
		...group,
	});

export const readGroupApi = async (
	urlParams: PageParams,
	body: BodyElement
): Promise<ReadResponse> =>
	await new api().request(
		`group/read?skip=${urlParams.skip}&limit=${urlParams.limit}${
			urlParams?.query ? `&query=${urlParams.query}` : ""
		}}`,
		"POST",
		body
	);

export const updateGroupApi = async (
	groupId: string,
	opt: EditableType<Group>
): Promise<{ group: Group }> =>
	new api().request("group/modify", "POST", {
		groupId: groupId,
		...opt,
	});

export const deleteGroupApi = async (
	groupId: string
): Promise<{
	user: {
		_id: string;
		groupIds: GroupItem[];
	};
	projects: {
		_id: string;
		owners: string[];
	}[];
}> =>
	new api().request("group/delete", "DELETE", {
		groupId,
	});

// ---------------------- PUBLISH GROUP MANAGEMENT (deprecated) ----------------------

/**
 * @deprecated
 */
export const publishManyInGroupApi = async (
	resources: Record<string, string[]>,
	groupId: string
) =>
	await new api().request(`group/publish-many`, "POST", {
		...resources,
		groupId,
	});

/**
 * @deprecated
 */
export const unpublishManyInGroupApi = async (
	resources: Record<string, string[]>,
	groupId: string
) =>
	await new api().request(`group/unpublish-many`, "POST", {
		...resources,
		groupId,
	});

// ---------------------- SHARE RESOURCE IN GROUP MANAGEMENT ----------------------

export const addResourcesToGroupApi = async (
	groupId: string,
	resourceType: ResourceType,
	resourceIds: string[]
): Promise<{
	resources: any[];
	other?: {
		Knowledge: Ontology;
	};
}> =>
	await new api().request("group-management/add-resources-to-group", "POST", {
		groupId,
		resourceType: formatRoute(resourceType),
		resourceIds,
	});

export const removeResourcesFromGroupApi = async (
	groupId: string,
	resourceType: ResourceType,
	resourceIds: string[]
): Promise<{
	resources: any[];
	other?: {
		Knowledge: Ontology;
	};
}> =>
	await new api().request(
		"group-management/remove-resources-from-group",
		"POST",
		{
			groupId,
			resourceType: formatRoute(resourceType),
			resourceIds,
		}
	);

export const removeResourceFromGroupApi = async (
	resourceId: string,
	resourceType: ResourceType,
	groupId: string
): Promise<{ msg: string }> =>
	await new api().request(
		"group-management/remove-resource-from-group",
		"POST",
		{
			resourceId,
			resourceType: formatRoute(resourceType),
			groupId,
		}
	);

// ---------------------- MEMBER MANAGEMENT ----------------------

export const addUsersToGroupApi = async (
	groupId: string,
	usersToBeModified: {
		email: string;
		role: string;
	}[]
): Promise<{ msg: string }> =>
	await new api().request("group-management/add-users-to-group", "POST", {
		usersToBeModified,
		groupId,
	});

export const listUsersInGroupApi = async (groupId: string): Promise<Member[]> =>
	await new api().request(
		`group-management/list-users-in-group?groupId=${groupId}`,
		"GET",
		{}
	);

export const changeUsersRoleInGroupApi = async (
	groupId: string,
	userIdsToBeModified: {
		id: string;
		role: string;
	}[]
): Promise<{
	user?: UserData;
	members: Member[];
}> =>
	await new api().request(
		"group-management/change-users-role-in-group",
		"POST",
		{
			userIdsToBeModified,
			groupId,
		}
	);

export const requestRolePromotionApi = async (
	groupId: string,
	role: UserType
): Promise<{ message: "Role promotion request sent." }> =>
	await new api().request("group/role-promotion-request", "POST", {
		groupId,
		role,
	});

export const removeUsersFromGroupApi = async (
	groupId: string,
	userIdsToBeModified: string[]
): Promise<{ group: Group; members: Member[] }> =>
	await new api().request(
		"group-management/remove-users-from-group",
		"POST",
		{
			groupId,
			userIdsToBeModified,
		}
	);

// ---------------------- NOTIFICATION GROUP MANAGEMENT ----------------------

export const acceptInvitationGroupApi = async (
	invitationId: string,
	accepted: boolean
): Promise<{ data?: Group; message: string }> =>
	await new api().request("group/accept-invitation", "POST", {
		invitationId,
		accepted,
	});

export const acceptRolePromotionRequestApi = async (
	requestId: string,
	accepted: boolean
): Promise<{ data?: Group; message: string }> =>
	await new api().request("group/role-promotion-accept", "POST", {
		requestId,
		accepted,
	});
