import { ResourceRealType } from "modules/types/global";
import { ResourceType } from "modules/types/global";
import { AnyAction } from "@reduxjs/toolkit";
import { store } from "../store";
import { GroupItem, UserData } from "modules/types/user";

export const setUser = (user: UserData): AnyAction => {
	return store.dispatch({
		type: "SET_USER",
		payload: user,
	});
};

export const updateUserGroups = (userData: {
	_id: string;
	groupIds: GroupItem[];
}): AnyAction => {
	return store.dispatch({
		type: "UPDATE_USER",
		payload: { userData },
	});
};

export const deleteUser = (user: UserData): AnyAction => {
	return store.dispatch({
		type: "DELETE_USER",
		payload: user,
	});
};

export const setLoggedIn = (loggedIn: boolean): AnyAction => {
	return store.dispatch({
		type: "SET_LOGGED_IN",
		payload: loggedIn,
	});
};

export const deleteResourceFromUser = (
	userKey: ResourceType,
	resource: ResourceRealType
): AnyAction => {
	return store.dispatch({
		type: "DELETE_Resource_TO_USER",
		payload: {
			userKey,
			resource,
		},
	});
};
