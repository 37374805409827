import { PatternMap, Pattern, PatternReducer } from "modules/types/pattern";
import { PayloadAction } from "@reduxjs/toolkit";

export const resetPatterns = (
	state: PatternReducer,
	action: PayloadAction<Pattern[]>
): PatternReducer => {
	let map: PatternMap = {};
	for (let pattern of action.payload) map[pattern?._id] = pattern;
	return {
		...state,
		map,
		init: true,
	};
};

export const setPatterns = (
	state: PatternReducer,
	action: PayloadAction<Pattern[]>
): PatternReducer => {
	let map: PatternMap = { ...state.map };
	for (let pattern of action.payload)
		map[pattern?._id] = { ...map[pattern?._id], ...pattern };
	return {
		...state,
		map,
		init: true,
	};
};

export const addPattern = (
	state: PatternReducer,
	action: PayloadAction<Pattern>
) => {
	let patternsMap: PatternMap = { ...state.map };
	patternsMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: patternsMap,
	};
};

export const updatePattern = (
	state: PatternReducer,
	action: PayloadAction<Pattern>
) => {
	let patternsMap: PatternMap = { ...state.map };
	patternsMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: patternsMap,
	};
};

export const updatePatterns = (
	state: PatternReducer,
	action: PayloadAction<Pattern[]>
) => {
	let patternsMap: PatternMap = { ...state.map };

	action.payload.forEach((_pattern) => {
		patternsMap[_pattern?._id] = _pattern;
	});

	return {
		...state,
		map: patternsMap,
	};
};

export const deletePattern = (
	state: PatternReducer,
	action: PayloadAction<{ patternId: string }>
) => {
	let patternsMap: PatternMap = { ...state.map };
	if (patternsMap[action.payload.patternId])
		delete patternsMap[action.payload.patternId];

	return {
		...state,
		map: patternsMap,
	};
};
