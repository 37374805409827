import {
	BodyElement,
	ResourceRealType,
	ResourceType,
} from "modules/types/global";
import { formatRoute } from "modules/utils/resource";
import { RequestService } from "services/request.service";

type ReadResponse = {
	count: number;
	documents: ResourceRealType[];
};

export class RetrieveResourceApi extends RequestService {
	/**
	 *
	 * @param resourceType
	 * @param body
	 * @returns
	 */
	protected retrieveResourcesApi = async (
		resourceType: ResourceType,
		body: BodyElement
	): Promise<ReadResponse> =>
		await this.request(`${formatRoute(resourceType)}/read`, "POST", body);
}
