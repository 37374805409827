import { formatCampaigns } from "modules/utils/category";
import { PayloadAction } from "@reduxjs/toolkit";
import {
	Campaign,
	CampaignMap,
	CampaignReducer,
	EvolutionData,
	KpiData,
} from "modules/types/annotation-campaign";

export const resetCampaigns = (
	state: CampaignReducer,
	action: PayloadAction<Campaign[]>
) => {
	let map: CampaignMap = {};
	let campaigns: Campaign[] = action.payload;

	campaigns = formatCampaigns(campaigns);
	for (let campaign of campaigns) {
		map[campaign?._id] = { ...map[campaign?._id], ...campaign };
	}

	return {
		...state,
		map,
		init: true,
	};
};

export const setCampaigns = (
	state: CampaignReducer,
	action: PayloadAction<Campaign[]>
) => {
	let map: CampaignMap = { ...state.map };
	let campaigns: Campaign[] = action.payload;
	campaigns = formatCampaigns(campaigns);
	for (let campaign of campaigns) {
		map[campaign?._id] = { ...map[campaign?._id], ...campaign };
	}

	return {
		...state,
		map,
		init: true,
	};
};

export const addCampaign = (
	state: CampaignReducer,
	action: PayloadAction<Campaign>
) => {
	let map = { ...state.map };
	let campaigns: Campaign[] = [];

	campaigns.push(action.payload);
	campaigns = formatCampaigns(campaigns);
	map[campaigns[0]?._id] = campaigns[0];

	return {
		...state,
		map,
	};
};

export const updateCampaign = (
	state: CampaignReducer,
	action: PayloadAction<Campaign>
) => {
	let map = { ...state.map };
	let campaigns: Campaign[] = [];

	campaigns.push(action.payload);
	campaigns = formatCampaigns(campaigns);
	map[campaigns[0]?._id] = campaigns[0];

	return {
		...state,
		map,
	};
};

export const deleteCampaign = (
	state: CampaignReducer,
	action: PayloadAction<string>
) => {
	let map = { ...state.map };
	delete map[action.payload];

	return {
		...state,
		map,
	};
};

/**
 * TODO: Complete Set campaign data
 * @param state
 * @param action
 * @returns
 */
export const setCampaignData = (
	state: CampaignReducer,
	action: PayloadAction<{ annotationCampaignId: string; data: [] }>
) => {
	let { annotationCampaignId, data } = action.payload;
	let dataMap = { ...state.dataMap };
	// let dataToAnnotate = data.shift();

	dataMap[annotationCampaignId] = data;

	return {
		...state,
		dataMap,
		dataToAnnotate: {},
	};
};

/**
 * TODO: Fix delete campaign data
 * @param state
 * @param action
 * @returns
 */
export const deleteCampaignData = (
	state: CampaignReducer,
	action: PayloadAction<string>
) => {
	let map = { ...state.map };
	return {
		...state,
		map,
	};
};

export const setKpiData = (
	state: CampaignReducer,
	action: PayloadAction<{ annotationCampaignId: string; kpiData: KpiData }>
) => {
	let { annotationCampaignId, kpiData } = action.payload;
	let map = { ...state.map };
	map[annotationCampaignId].kpiData = kpiData;

	return {
		...state,
		map,
	};
};

export const setEvolutionData = (
	state: CampaignReducer,
	action: PayloadAction<{
		annotationCampaignId: string;
		evolutionData: EvolutionData;
	}>
) => {
	let { annotationCampaignId, evolutionData } = action.payload;
	let map = { ...state.map };
	map[annotationCampaignId].evolutionData = evolutionData;

	return {
		...state,
		map,
	};
};
