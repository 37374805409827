/*eslint no-unreachable: "off"*/
import { DictionaryReducer } from "modules/types/dictionary";
import { AnyAction } from "@reduxjs/toolkit";
import * as methods from "./dictionary-methods";
import {
	CountActionType,
	DictionariesActionType,
	DictionaryActionType,
	SearchQueryActionType,
	WordActionType,
	WordsActionType,
} from "modules/types/dictionary/store";

const initialState: DictionaryReducer = {
	init: false,
	map: {},
	words: [],
	synonyms: [],
	dictionaryWords: [],
	count: 0,
};

export const dictionaryReducer = (
	state = initialState,
	action: AnyAction
): DictionaryReducer => {
	switch (action.type) {
		case "RESET_DICTIONARIES": {
			return methods.resetDictionaries(
				state,
				action as DictionariesActionType
			);
			break;
		}
		case "SET_DICTIONARIES": {
			return methods.setDictionaries(
				state,
				action as DictionariesActionType
			);
			break;
		}
		case "ADD_DICTIONARY": {
			return methods.addDictionary(state, action as DictionaryActionType);
			break;
		}
		case "UPDATE_DICTIONARY": {
			return methods.updateDictionary(
				state,
				action as DictionaryActionType
			);
			break;
		}
		case "DELETE_DICTIONARY": {
			return methods.deleteDictionary(state, action as any);
			break;
		}
		case "ADD_DICTIONARY_WORD": {
			return methods.addDictionaryWord(state, action as WordActionType);
			break;
		}
		case "SET_DICTIONARY_WORDS": {
			return methods.setDictionaryWords(state, action as WordsActionType);
			break;
		}
		case "DELETE_DICTIONARY_WORD": {
			return methods.deleteDictionaryWord(
				state,
				action as WordActionType
			);
			break;
		}
		case "SET_SYNONYMS": {
			return methods.setSynonyms(state, action as WordsActionType);
			break;
		}
		case "DELETE_SYNONYM": {
			return methods.deleteSynonym(state, action as WordActionType);
			break;
		}
		case "SET_WORDS": {
			return methods.setWords(state, action as WordsActionType);
			break;
		}
		case "CLEAR_DICTIONARY_WORDS": {
			return methods.clearDictionaryWords(state);
			break;
		}
		case "SET_COUNT": {
			return methods.setCount(state, action as CountActionType);
			break;
		}
		case "SET_SEARCH_QUERY": {
			return methods.setSearchQuery(
				state,
				action as SearchQueryActionType
			);
			break;
		}
		default: {
			break;
		}
	}
	return state;
};
