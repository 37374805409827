/*eslint no-unused-vars:0*/
import { AnyAction } from "@reduxjs/toolkit";
import { Asset, Dataset } from "modules/types/dataset";
import { store } from "store/store";

/************************************************************************************************
 * DATASETS ACTIONS
 ************************************************************************************************/
export const resetDatasets = (datasets: Dataset[]): AnyAction => {
	return store.dispatch({
		type: "RESET_DATASETS",
		payload: datasets,
	});
};

export const setDatasets = (datasets: Dataset[]): AnyAction => {
	return store.dispatch({
		type: "SET_DATASETS",
		payload: datasets,
	});
};

export const addDataset = (dataset: Dataset): AnyAction => {
	return store.dispatch({
		type: "ADD_DATASET",
		payload: dataset,
	});
};

export const updateDataset = (dataset: Dataset): AnyAction => {
	return store.dispatch({
		type: "UPDATE_DATASET",
		payload: dataset,
	});
};

export const deleteDataset = (datasetId: string): AnyAction => {
	return store.dispatch({
		type: "DELETE_DATASET",
		payload: { datasetId },
	});
};

/************************************************************************************************
 * ASSETS ACTIONS
 ************************************************************************************************/
export const resetAssets = (assets: Asset[]): AnyAction => {
	return store.dispatch({
		type: "RESET_ASSETS",
		payload: assets,
	});
};

export const setAssets = (assets: Asset[]): AnyAction => {
	return store.dispatch({
		type: "SET_ASSETS",
		payload: assets,
	});
};

export const deleteAssets = (
	datasets: Dataset[],
	assetIds: string[]
): AnyAction => {
	return store.dispatch({
		type: "DELETE_ASSETS",
		payload: { datasets, assetIds },
	});
};
