import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { type RootState, type AppDispatch, store } from "./store";
import { PrimaryResource } from "modules/types/global";
import { GroupItem, UserType } from "modules/types/user";

import { Campaign } from "modules/types/annotation-campaign";
import { Dataset } from "modules/types/dataset";
import { FileStorage } from "modules/types/file-storage";
import { Ontology, KnowledgeCard, ClassProperty } from "modules/types/ontology";
import { Pattern } from "modules/types/pattern";
import { Project } from "modules/types/project";
import { Training } from "modules/types/training";
import { Dictionary } from "modules/types/dictionary";
import { AnnotationDataV2, AnnotationItemV2 } from "modules/types/annotation";
import {
	MLFlowExperiment,
	MLFlowModelVersion,
	MLFlowRun,
} from "modules/types/training/ml-flow";
import { AirflowDagRun } from "modules/types/training/airflow";
import { Group, Member } from "modules/types/group";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type RessourceMaps = {
	groupMap: Record<string, Group>;
	datasetMap: Record<string, Dataset>;
	dictionaryMap: Record<string, Dictionary>;
	fileStorageMap: Record<string, FileStorage>;
	projectMap: Record<string, Project>;
	trainingMap: Record<string, Training>;
	annotationCampaignMap: Record<string, Campaign>;
	ontologyMap: Record<string, Ontology>;
	patternMap: Record<string, Pattern>;
	annotationDataMap: Record<string, AnnotationDataV2>;
	annotationItemMap: Record<string, AnnotationItemV2>;
	knowledgeCardMap: Record<string, KnowledgeCard>;
	classPropertyMap: Record<string, ClassProperty>;
	experimentMap: Record<string, MLFlowExperiment>;
	runMap: Record<string, MLFlowRun>;
	dagRunMap: Record<string, AirflowDagRun>;
	modelVersionMap: Record<string, MLFlowModelVersion>;

	// Unknown
	dataMap: Record<string, any>;
	manageDataMap: Record<string, any>;
};

const primaryResourceKeys = [
	"dataset",
	"dictionary",
	"fileStorage",
	"project",
	"training",
	"annotationCampaign",
	"ontology",
	"pattern",
];

const secondaryResourceKeys = [
	"annotationData",
	"annotationItem",
	"knowledgeCard",
	"classProperty",
	"experiment",
	"run",
	"dagRun",
	"modelVersion",
	"data",
	"manageData",
];

export const canCreateRoles: UserType[] = [
	"Admin",
	"SuperUser",
	"SuperAnnotator",
	"Annotator",
	"User",
];
export const canReadRoles: UserType[] = [
	"Admin",
	"SuperUser",
	"SuperAnnotator",
	"Annotator",
	"User",
];
export const canUpdateRoles: UserType[] = ["Admin", "SuperUser"];
export const canDeleteRoles: UserType[] = ["Admin"];

export const getAbilities = (resourceId: string): Abilities => {
	const state = { ...store.getState() };
	const user = state.user.data;

	let role: UserType = "User";

	if (user && resourceId) {
		const groupIdsMap = user.groupIds.reduce(
			(acc, groupItem) => ({
				...acc,
				[groupItem.id]: groupItem,
			}),
			{} as Record<string, GroupItem>
		);

		if (groupIdsMap[resourceId]) {
			role = groupIdsMap[resourceId].role;
		} else {
			const globalReduxMap = getGlobalReduxMap();
			if (resourceId && globalReduxMap[resourceId]) {
				const resource = globalReduxMap[resourceId];
				if (user.groupIds.length) {
					if (resource.owners) {
						role = resource.owners.reduce(
							(acc: UserType, groupId) => {
								if (groupIdsMap[groupId]) {
									const groupItem = groupIdsMap[groupId];
									switch (groupItem.role) {
										case "Admin":
											acc = groupItem.role;
											break;
										case "SuperUser":
											if (acc !== "Admin")
												acc = groupItem.role;
											break;
										case "SuperAnnotator":
											if (
												acc !== "Admin" &&
												acc !== "SuperUser"
											)
												acc = groupItem.role;
											break;
										case "Annotator":
											if (
												acc !== "Admin" &&
												acc !== "SuperUser" &&
												acc !== "SuperAnnotator"
											)
												acc = groupItem.role;
											break;
										default:
											break;
									}
								}
								return acc;
							},
							role as UserType
						);
					} else {
						// TODO: Add condition for secondary resources that don't have owners field
						// Look for the primary resource that owns the secondary resource
					}
				}
			}
		}
	}

	return {
		role,
		canCreate: canCreateRoles.includes(role),
		canRead: canReadRoles.includes(role),
		canUpdate: canUpdateRoles.includes(role),
		canDelete: canDeleteRoles.includes(role),
	};
};

export type Abilities = {
	readonly role: UserType;
	readonly canCreate: boolean;
	readonly canRead: boolean;
	readonly canUpdate: boolean;
	readonly canDelete: boolean;
};

export const getGlobalReduxMap = () => {
	const state = { ...store.getState() };
	return Object.keys(state).reduce((acc, key) => {
		// Filter on primary resource reducers
		if (primaryResourceKeys.includes(key)) {
			const reducer = { ...state[key as keyof typeof state] };
			if (reducer) {
				const reducerMapKeys = Object.keys(reducer).filter(
					(k) => k.endsWith("Map") || k === "map"
				);
				if (reducerMapKeys.length) {
					reducerMapKeys.forEach((reducerMapKey) => {
						acc = {
							...acc,
							...(!Array.isArray(
								reducer[reducerMapKey as keyof typeof reducer]
							)
								? reducer[reducerMapKey as keyof typeof reducer]
								: {}),
						};
					});
				}
			}
		}
		return acc;
	}, {} as Record<string, PrimaryResource>);
};

/**
 * Get resource groups from owners
 * @param owners
 */
export const getResourceGroups = (owners: string[]) => {
	const state = { ...store.getState() };
	const groupMap = state.group.map;
	return owners.reduce(
		(acc, groupId) =>
			groupMap[groupId] ? { ...acc, [groupId]: groupMap[groupId] } : acc,
		{} as Record<string, Group>
	);
};

export const getUserGroups = () => {
	const state = { ...store.getState() };
	const user = state.user.data;
	if (user)
		return user.groupIds.reduce(
			(acc, groupItem) => ({
				...acc,
				[groupItem.id]: groupItem,
			}),
			{} as Record<string, GroupItem>
		);
	return {};
};

/**
 * Return a map of all members of the groups in parameter or all groups
 * @param owners
 * @returns
 */
export const getGroupsMemberMap = (owners?: string[]) => {
	const state = { ...store.getState() };
	const memberArrayToMap = (members: Member[]) =>
		members.reduce(
			(acc, member) => ({ ...acc, [member._id]: member }),
			{} as Record<string, Member>
		);
	if (owners) {
		return owners.reduce((acc, groupId) => {
			if (state.group.members[groupId])
				return {
					...acc,
					...memberArrayToMap(state.group.members[groupId]),
				};
			return acc;
		}, {} as Record<string, Member>);
	} else {
		return Object.keys(state.group.members).reduce(
			(acc, groupId) => ({
				...acc,
				...memberArrayToMap(state.group.members[groupId]),
			}),
			{} as Record<string, Member>
		);
	}
};

/**
 * Get all resource maps from the store
 * @returns RessourceMaps
 */
// TODO: To test
export const getAllResourceMaps = (): RessourceMaps => {
	const state = { ...store.getState() };
	return Object.keys(state).reduce((acc, key) => {
		// Filter on primary resource reducers
		if (primaryResourceKeys.includes(key)) {
			const reducer = { ...state[key as keyof typeof state] };
			if (reducer) {
				Object.keys(reducer).forEach((k) => {
					if (k === "map") {
						acc = {
							...acc,
							[key + "Map"]: reducer[k as keyof typeof reducer],
						};
					} else if (k.endsWith("Map")) {
						acc = {
							...acc,
							[k]: reducer[k as keyof typeof reducer],
						};
					}
				});
			}
		}
		return acc;
	}, {} as RessourceMaps);
};
