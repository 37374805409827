import { RootState } from "store/store";
import { connect, ConnectedProps } from "react-redux";
import { Routes, Route, Navigate } from "react-router";
import React, { Suspense } from "react";
import "./styles/routes.style.less";

const HomeRoutesComponent = React.lazy(
	() => import("./../../../private/home/home.routes")
);

const AdminRoutesComponent = React.lazy(
	() => import("./../../../private/admin/admin.routes")
);
const NlpRoutesComponent = React.lazy(
	() => import("./../../../private/nlp/nlp.routes")
);
const AnnotationRoutesComponent = React.lazy(
	() => import("./../../../private/annotation/annotation.routes")
);
const StructurationRoutesComponent = React.lazy(
	() => import("./../../../private/structuration/structuration.routes")
);

const PrivateRoutes = (props: RoutesManagerProps) => {
	return (
		<Suspense fallback={<></>}>
			<Routes>
				<Route>
					<Route path="/*" element={<HomeRoutesComponent />} />
					<Route path="nlp/*" element={<NlpRoutesComponent />} />
					<Route path="admin/*" element={<AdminRoutesComponent />} />
					<Route
						path="annotation/*"
						element={<AnnotationRoutesComponent />}
					/>
					<Route
						path="structuration/*"
						element={<StructurationRoutesComponent />}
					/>
					<Route
						path="*"
						element={
							<>
								<Navigate replace to="/" />
							</>
						}
					/>
				</Route>
			</Routes>
		</Suspense>
	);
};

const mapState = (state: RootState) => {
	let userRole: string = "";
	if (state.user && state.user.data)
		userRole = state.user.data?.role as string;

	return {
		role: userRole,
	};
};
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RoutesManagerProps = PropsFromRedux;

export default connector(PrivateRoutes);
