/* eslint-disable react-hooks/exhaustive-deps */
import PrivateRoutes from "./routes/private.routes";
import { RetrieveDataService } from "services/retrieve-data/retrieve-data.service";
import { useEffect, useState } from "react";
import { Spinner } from "common/components/spinner/spinner.component";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/store";
import { useLocation } from "react-router";
import { listUsersInGroupStore } from "modules/lib/group";

type PrivateLayoutOwnProps = {};

const PrivateLayout = (props: PrivateLayoutProps) => {
	const location = useLocation();
	const [isRetrieve, setIsRetrieve] = useState<boolean>(false);
	const [showContent, setShowContent] = useState(false);

	const service = new RetrieveDataService();

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowContent(true);
		}, 600);
		return () => {
			clearTimeout(timer);
		};
	}, []);

	useEffect(() => {
		trackCustomerNavigation();
	}, [location.pathname]);

	useEffect(() => {
		(async () => {
			try {
				if (isRetrieve || !props.user?._id) return;
				service.retrieveNotifications();
				service.retrieveSubscriptions();
				await service.retrieveResources([
					{
						type: "project",
						map: props.projectMap,
					},
				]);
				service.retrieveResources([
					{
						type: "group",
						map: props.groupMap,
					},
				]);

				if (props?.user?.groupIds?.length)
					listUsersInGroupStore(
						props.user.groupIds
							.filter((g) => g.type === "Default")
							.map((g) => g.id)
					);
			} catch (error) {
				console.error(error);
			}
			setIsRetrieve(true);
		})();
	}, [props.user]);

	const trackCustomerNavigation = () => {
		if (window?._cio?.page) {
			let split = location.pathname.split("/")?.filter((e) => e !== "");
			window._cio.page(location.pathname, {
				path: location.pathname,
				zone: split?.[0],
				resourceType: split?.[1],
				page: split?.[2],
				pageDetail1: split?.[3],
				pageDetail2: split?.[4],
				pageDetail3: split?.[5],
				pageDetail4: split?.[6],
				pageDetail5: split?.[7],
				pageDetail6: split?.[8],
			});
		}
	};

	return (
		<>
			<PrivateRoutes />
			{!showContent || !isRetrieve ? (
				<div
					style={{
						display: "flex",
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: 9999,
						backgroundColor: "white",
						overflow: "hidden",
					}}>
					<Spinner lettriaLogo />
				</div>
			) : null}
		</>
	);
};

const mapState = (state: RootState) => ({
	user: state.user.data,
	groupMap: state.group.map,
	projectMap: state.project.map,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
type PrivateLayoutProps = PropsFromRedux & PrivateLayoutOwnProps;

export default connector(PrivateLayout);
