import { ProjectMap, Project, ProjectReducer } from "modules/types/project";
import { PayloadAction } from "@reduxjs/toolkit";
import { formatKnowledgeBeforeRedux } from "modules/utils/ontology";

export const resetProjects = (
	state: ProjectReducer,
	action: PayloadAction<Project[]>
): ProjectReducer => {
	let projects = formatKnowledgeBeforeRedux(action.payload) as Project[];
	let map: ProjectMap = {};

	projects.forEach((project) => {
		map[project?._id] = project;
	});
	return {
		...state,
		map,
		init: true,
	};
};

export const setProjects = (
	state: ProjectReducer,
	action: PayloadAction<Project[]>
): ProjectReducer => {
	let projects = formatKnowledgeBeforeRedux(action.payload) as Project[];
	let map: ProjectMap = { ...state.map };

	projects.forEach((project) => {
		map[project?._id] = { ...map[project?._id], ...project };
	});
	return {
		...state,
		map,
		init: true,
	};
};

export const updateProjectsGroups = (
	state: ProjectReducer,
	action: PayloadAction<{
		projects: {
			_id: string;
			owners: string[];
		}[];
	}>
): ProjectReducer => {
	const { projects } = action.payload;
	let map: ProjectMap = { ...state.map };

	projects.forEach((project) => {
		map[project._id] = { ...map[project._id], ...project };
	});
	return {
		...state,
		map,
	};
};

export const addProject = (
	state: ProjectReducer,
	action: PayloadAction<Project>
) => {
	let projectsMap: ProjectMap = { ...state.map };
	projectsMap[action.payload?._id] = action.payload;
	return {
		...state,
		map: projectsMap,
	};
};

export const updateProject = (
	state: ProjectReducer,
	action: PayloadAction<Project>
) => {
	let projects = formatKnowledgeBeforeRedux([action.payload]) as Project[];
	let map: ProjectMap = { ...state.map };
	map[projects[0]?._id] = projects[0];

	return {
		...state,
		map,
	};
};

export const deleteProject = (
	state: ProjectReducer,
	action: PayloadAction<{ projectId: string }>
) => {
	let projectsMap: ProjectMap = { ...state.map };
	if (projectsMap[action.payload.projectId])
		delete projectsMap[action.payload.projectId];

	return {
		...state,
		map: projectsMap,
	};
};
