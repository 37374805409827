import { AnnotationToken } from "modules/types/annotation";

/**
 * Rewrite string in fucntion of the limit
 * @param string
 * @param limit
 * @returns
 */
export const limitStringLength = (string: string, limit: number) => {
	if (string && string.length > limit)
		return string.substring(0, limit >= 2 ? limit - 2 : 1) + " ...";
	return string;
};

/**
 * Convert string array to string with spaces
 * @param array
 * @returns
 */
export const arrayToString = (array: string[]) => {
	let string = "";
	array.forEach((element) => {
		string += element + " ";
	});
	return string;
};

/**
 * experimental
 * @param string
 * @returns
 */
export const removePluralString = (string: string) => {
	if (string.toLowerCase().charAt(string.length - 1) === "s")
		return string.toLowerCase().slice(0, -1);
	else if (string.toLowerCase().slice(-4) === "eaux")
		return string.toLowerCase().slice(0, -1);
	else if (string.length > 3 && string.toLowerCase().slice(-3) === "aux")
		return string.slice(0, -3).concat("al");
	return string;
};

/**
 * Normalize string
 * @param str
 * @returns
 */
export const getNormalizedString = (str: string) => {
	str = str.replace(/\s+/g, " ").trim();
	return str ? removeDiacritics(str.toLowerCase()) : "";
};

/**
 * Remove accent (use  *getNormalizedString*  instead)
 * @param str
 * @returns
 */
export const removeDiacritics = (str: string) => {
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

/**
 * Copy *text* to clipboard
 * @param text to copy
 */
export const copyToClipboard = (text: string) => {
	const selBox = document.createElement("textarea");
	selBox.style.position = "fixed";
	selBox.style.left = "0";
	selBox.style.top = "0";
	selBox.style.opacity = "0";
	selBox.value = text;
	document.body.appendChild(selBox);
	selBox.focus();
	selBox.select();
	document.execCommand("copy");
	document.body.removeChild(selBox);
};

export const generateNameByIncrement = (name: string) => {
	let items = name.split(" ");
	if (items[items.length - 1]) {
		const lastItem = items[items.length - 1];
		if (
			lastItem.charAt(0) === "(" &&
			lastItem.charAt(lastItem.length - 1) === ")"
		) {
			const lastItemNumber = parseInt(
				lastItem.substring(1, lastItem.length - 1)
			);
			if (!isNaN(lastItemNumber)) {
				items[items.length - 1] =
					"(" + (lastItemNumber + 1).toString() + ")";
				return items.join(" ");
			}
		}
	}
	return name + " (1)";
};

/**
 * Capitalize first letter and lowerize the rest.
 * Replace _ by space
 * @param str
 * @returns
 */
export const CapitalizeFirstAndLowerize = (str: string) => {
	return (
		str.charAt(0).toUpperCase() +
		str.slice(1).toLowerCase().replace(/_/g, " ")
	);
};

/**
 * Replace all occurences of *search* by *replace* in *string*
 * @param string
 * @returns
 */
export const escapeRegex = (string: string) => {
	return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
};

/**
 * Convert number to percent
 * * if value is a number, return the percent equivalent
 * * if value is undefined, return "-"
 * @param value
 * @returns
 */
export const showPercent = (value: any) => {
	if (typeof value === "number") {
		return (value * 100)?.toFixed(2) + " %";
	}
	if (!value) return "-";
	return value;
};

/**
 * Test function: convert indexes from char to text or text to char
 */
export const main = () => {
	const arrayInit: {
		word: string;
		indexes: {
			start: number;
			end: number;
		};
	}[] = [
		{
			word: "This",
			indexes: {
				start: 0,
				end: 3,
			},
		},
		{
			word: "is",
			indexes: {
				start: 5,
				end: 6,
			},
		},
		{
			word: "an",
			indexes: {
				start: 8,
				end: 9,
			},
		},
		{
			word: "example",
			indexes: {
				start: 11,
				end: 17,
			},
		},
		{
			word: "text",
			indexes: {
				start: 19,
				end: 22,
			},
		},
		{
			word: ".",
			indexes: {
				start: 23,
				end: 23,
			},
		},
		{
			word: "Another",
			indexes: {
				start: 25,
				end: 31,
			},
		},
		{
			word: "example",
			indexes: {
				start: 33,
				end: 39,
			},
		},
		{
			word: ".",
			indexes: {
				start: 40,
				end: 40,
			},
		},
	];

	console.log({
		arrayInit,
		arrayCharIndexes: convertIndexes(
			convertIndexes(
				arrayInit.map(({ indexes }) => indexes),

				"text"
			),
			"char"
		),
		arrayTextIndexes: convertIndexes(
			arrayInit.map(({ indexes }) => indexes),

			"text"
		),
	});
};

/**
 * Convert indexes from char to text or text to char
 * @param indexes
 * @param target
 * @returns
 */
export const convertIndexes = (
	indexes: AnnotationToken[],
	target: "text" | "char"
) => {
	switch (target) {
		case "text":
			return indexes.map(({ start, end }) => ({
				start,
				end: end + 1,
			}));
		case "char":
			return indexes.map(({ start, end }) => ({
				start,
				end: end - 1,
			}));
	}
};

/**
 * Return wether the email is valid or not
 * @param email {String} email to validate
 * @returns
 */
export const validateEmail = (email: string) =>
	String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
