import { labelColors } from "modules/data/colors/colors";
import { ClassProperty } from "modules/types/ontology";
import { getNormalizedString } from "../string";

/**
 * Returns the color of the label
 * @param labels
 * @param category
 * @param defaultColor
 * @returns
 */
export const findProjectLabel = (
	labels: ClassProperty[],
	category: string | undefined
): ClassProperty | null => {
	if (category) {
		if (labels.length) {
			const _label = labels.find((label) => {
				if (Array.isArray(category)) {
					return category.includes(label.label);
				}
				return (
					getNormalizedString(category) ===
					getNormalizedString(label.label)
				);
			});
			if (_label) {
				return _label;
			}
		}
	}
	return null;
};

export const handleLabelColor = (
	labels: ClassProperty[],
	category: string | undefined,
	defaultColor: "grey" | "transparent" | "lightgrey"
) => {
	if (category) {
		if (labels.length) {
			const _label = labels.find((label) => {
				if (Array.isArray(category)) {
					return category.includes(label.label);
				}
				return (
					getNormalizedString(category) ===
					getNormalizedString(label.label)
				);
			});
			if (_label) {
				if (
					_label.metadata.annotationColor &&
					_label.metadata.annotationColor !== "grey"
				) {
					return _label.metadata.annotationColor;
				} else {
					return getLabelColor(_label.label);
				}
			}
		} else {
			return getLabelColor(category);
		}
	}
	return defaultColor;
};

/**
 * Calculates the color of the label
 * @param item
 * @returns
 */
export const getLabelColor = (item: string | string[]) => {
	try {
		let string = Array.isArray(item)
			? item.join("")
			: typeof item === "string"
			? item
			: "";
		return string?.split("")?.length
			? labelColors.colors[
					string
						.split("")
						.reduce((sum, char) => sum + char.charCodeAt(0), 0) %
						labelColors.colors.length
			  ]
			: "#028CFF";
	} catch (error) {
		console.log("getLabelColor error", error);
		return "#028CFF";
	}
};

export const getLabelColorFromExistingLabels = (labels: ClassProperty[]) => {
	// returns the least used color among the received colors
	const leastUsedColor = labelColors.colors.reduce(
		(leastUsedColor, color) => {
			const colorCount = labels.filter(
				(label) => label.metadata.annotationColor === color
			).length;
			if (
				colorCount < leastUsedColor.count ||
				leastUsedColor.count === -1
			) {
				return { color, count: colorCount };
			}
			return leastUsedColor;
		},
		{ color: "grey", count: -1 }
	);
	return leastUsedColor.color;
};

export const getUniqueColor = (colors: string[]) => {
	const leastUsedColor = labelColors.colors.reduce(
		(leastUsedColor, color) => {
			const colorCount = colors.filter((label) => label === color).length;
			if (
				colorCount < leastUsedColor.count ||
				leastUsedColor.count === -1
			) {
				return { color, count: colorCount };
			}
			return leastUsedColor;
		},
		{ color: "grey", count: -1 }
	);
	return leastUsedColor.color;
};

export const colors = [
	"#E4A951",
	"#51C1E4",
	"#53B625",
	"#C23478",
	"#6343E4",
	"#00FFFF",
	"#58ff47",
	"#B69AFF",
	"#6700F9",
	"#4C006F",
	"#00816C",
	"#72FF9B",
	"#6A6C00",
	"#E2C88C",
	"#63C2FE",
	"#DA0084",
	"#593200",
	"#40BDAB",
	"#B49B00",
	"#F200FF",
	"#930000",
	"#004E74",
	"#f31138",
	"#e68739",
	"#dcc391",
	"#417c5b",
	"#226448",
	"#3D4B50",
	"#481E35",
	"#7B7FC8",
	"#91BDB7",
	"#E6D6BE",
	"#503726",
	"#9C3950",
	"#AB5D73",
	"#7D7B7A",
	"#577EA4",
	"#293048",
	"#2BA333",
	"#13386B",
	"#9D4529",
	"#97DB5C",
	"#434766",
	"#1F0F11",
	"grey",
];

export const fillColorsMap: { [key: string]: string } = {
	"#DBAB60": "#f8eede",
	"#70BFE0": "#e0f2f9",
	"#6DB441": "#e1efd5",
	"#B34076": "#eed7e3",
	"#5E44DC": "#dfd9f7",
	"#75FBFD": "#d6fefe",
	"#8BFC65": "#e4fedd",
	"#B19BF8": "#efebfd",
	"#5E0EEF": "#ddcdfa",
	"#45086B": "#d8cde0",
	"#387F6D": "#d1e5e2",
	/// TODO: continue list, tool commented on rdf-graph-view.tsx:251
	"#EAC36E": "#faf3e1",
	"#8650FF": "#e5dcfc",
	"#4CDB7D": "#e1f7e6",
	"#E07EE9": "#f6e6f9",
	"#F2C15E": "#faf3e1",
	"#AC5700": "#ebdece",
	"#1CDBD8": "#d9f7f7",
	"#028CFF": "#d1e7fd",
	// "#028CFF": "@d1e7fd",
	// "#4CDB7D": "#faf3e1",
	// "#E07EE9": "#faf3e1",
	"#92BAFF": "#eaf1fe",
	"#D3D3D3": "#f6f6F6",
	"#FE805B": "#FCE7E0",
	// "#A62848": "#faf3e1",
	// "#8F7E71": "#faf3e1",
	// "#A2CD6C": "#faf3e1",
	// "#158149": "#faf3e1",
	// "#0339B4": "#faf3e1",
	// "#BA72B7": "#faf3e1",
	// "#3FA4B1": "#faf3e1",
	// "#3FA543": "#faf3e1",
	// "#A35FAE": "#faf3e1",
	// "#660084": "#faf3e1",
	// "#DAB397": "#faf3e1",
};
