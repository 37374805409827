import { Group } from "modules/types/group";
import { setGroups } from "store/group/group-actions";
import { setProjects } from "store/project/project-actions";
import { Project } from "modules/types/project";
import { ClassProperty, KnowledgeCard, Ontology } from "modules/types/ontology";
import {
	setClassProperties,
	setOntologies,
	setProjectOntologies,
	updateKnowledgeCards,
} from "store/ontology/actions";
import { setDatasets } from "store/dataset/dataset-actions";
import { Dataset } from "modules/types/dataset";
import { Pattern } from "modules/types/pattern";
import { setPatterns } from "store/pattern/pattern-actions";
import { Dictionary } from "modules/types/dictionary";
import { setDictionaries } from "store/dictionary/dictionary-actions";
import { Campaign } from "modules/types/annotation-campaign";
import {
	BodyElement,
	ResourceRealType,
	ResourceType,
} from "modules/types/global";
import { setCampaigns } from "store/annotation-campaign/annotation-campaign-actions";
import { RetrieveResourceApi } from "../api/retrieve-resource.api.service";
import { formatProject } from "modules/utils/project";
import { setFileStorages } from "store/file-storage/file-storage-actions";
import { FileStorage } from "modules/types/file-storage";
import { setTrainings } from "store/training/actions/training-actions";
import { Training, TrainingOutput } from "modules/types/training";
import { setTrainingOutputs } from "store/training/actions";

export class RetrieveResourceStore extends RetrieveResourceApi {
	/**
	 *
	 * @param resourceType
	 * @param body
	 * @returns
	 */
	public retrieveResourcesStore = async (
		resourceType: ResourceType,
		body: BodyElement
	) => {
		const { documents, count } = await this.retrieveResourcesApi(
			resourceType,
			body
		);
		if (Array.isArray(documents) && documents.length) {
			this.setDocuments(resourceType, documents);
			return { documents, count };
		}
		return false;
	};
	private setDocuments = (
		resourceType: ResourceType,
		documents: ResourceRealType[]
	) => {
		switch (resourceType) {
			case "annotation-campaign":
				setCampaigns(documents as Campaign[]);
				break;
			case "training":
				setTrainings(documents as Training[]);
				break;
			case "training-output":
				setTrainingOutputs(documents as TrainingOutput[]);
				break;
			case "class-property":
				setClassProperties(documents as ClassProperty[]);
				break;
			case "dataset":
				setDatasets(documents as Dataset[]);
				break;
			case "dictionary":
				setDictionaries(documents as Dictionary[]);
				break;
			case "file-storage":
				setFileStorages(documents as FileStorage[]);
				break;
			case "group":
				setGroups(documents as Group[]);
				break;
			case "knowledge-card":
				if (documents.length) {
					updateKnowledgeCards(
						(documents as KnowledgeCard[])[0].knowledgeId,
						documents as KnowledgeCard[]
					);
				}
				break;
			case "ontology":
				setOntologies(documents as Ontology[]);
				break;
			case "pattern":
				setPatterns(documents as Pattern[]);
				break;
			case "project":
				setProjects(
					(documents as Project[]).map((_project) => {
						const { ontology, project } = formatProject(_project);
						if (ontology) setProjectOntologies([ontology]);
						return project;
					})
				);
				break;
		}
	};
}
