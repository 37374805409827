export const getNested = function (obj: any, key: string) {
	return key
		.split(".")
		.reduce(
			(o, x) => (typeof o === "undefined" || o === null ? o : o[x]),
			obj
		);
};

export const setNested = function (obj: any, key: any, value: any) {
	let keys = key.split("."),
		curs = obj;
	try {
		for (var i = 0; i < keys.length - 1; i++) {
			if (keys[i]) curs = curs[keys[i]];
		}
		curs[keys[keys.length - 1]] = value;
	} catch (e) {
		console.error(e);
	}
	return obj;
};

export const isInside = (
	range: [number, number],
	idx: number,
	params?: {
		strict?: boolean;
		strictInf?: boolean;
		strictSup?: boolean;
	}
) => {
	if (!range) return false;
	if (params) {
		if (params.strict) {
			return idx > range[0] && idx < range[1];
		}
		if (params.strictInf) {
			return idx >= range[0] && idx < range[1];
		}
		if (params.strictSup) {
			return idx > range[0] && idx <= range[1];
		}
	}
	return idx >= range[0] && idx <= range[1];
};

/**
 * Return true if range1 and range2 are not overlapping
 * @param range1
 * @param range2
 * @returns
 */
export const isNoOverlap = (
	range1: [number, number],
	range2: [number, number]
) => range1[1] < range2[0] || range2[1] < range1[0];

/**
 * Return true if no ranges are overlapping
 * @param range1
 * @param range2
 * @returns
 */
export const isNoRangesOverlap = (
	range1: [number, number][],
	range2: [number, number][]
) => {
	for (let r1 of range1) {
		for (let r2 of range2) {
			if (!isNoOverlap(r1, r2)) {
				return false;
			}
		}
	}
	return true;
};

export const isEqual = (a: number[], b: number[]) => {
	if (a.length !== b.length) return false;
	a = a.sort((a, b) => a - b);
	b = b.sort((a, b) => a - b);
	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) return false;
	}
	return true;
};

/**
 * Find available key in map
 * * map keys must be numbers
 * @return {number} key available in map
 */
export const keyMapGenerator = (map: { [key: number]: any }) => {
	const keys = Object.keys(map).map((k) => parseInt(k));
	if (!keys.length) return 0;
	const max = Math.max(...keys);
	return max + 1;
};

export const parseObj = (obj: any) => JSON.parse(JSON.stringify(obj));
