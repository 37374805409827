import { ObjectKey, ResourceType } from "modules/types/global";

/**
 * Generatate a route from a resource type
 * @param resourceType
 * @param body
 * @returns
 */
export const formatRoute = (
	resourceType: ResourceType,
	body = false,
	url = false
) => {
	switch (resourceType) {
		case "annotation-campaign":
			return body
				? "annotationCampaign"
				: url
				? "campaign"
				: "annotation-campaign";
		// case "annotation-data":
		// 	return body ? "annotationData" : "annotation-data";
		// case "annotation-item":
		// 	return body ? "annotationItem" : "annotation-item";
		case "training":
			return body ? "annotationTraining" : "annotation-training";
		case "training-output":
			return body
				? "annotationTrainingOutput"
				: "annotation-training-output";
		case "class-property":
			return body ? "classProperty" : "class-property";
		case "dictionary":
			return "dictionary";
		case "file-storage":
			return body ? "fileStorage" : "file-storage";
		case "knowledge-card":
			return body ? "knowledgeCard" : "knowledge-card";
		case "pattern":
			return "pattern";
		case "dataset":
			return body ? "dataSet" : "data-set";
		case "ontology":
			return "knowledge";
		case "group":
			return "group";
		case "project":
			return "project";
	}
};

/**
 * Generate body for the request
 * @param array {value to save, object route resource Type, object key}
 * @returns body for api
 */
export const formatBody = (
	array: {
		value: any | any[];
		resourceType: ResourceType;
		key: ObjectKey;
	}[]
) => {
	let keys: { [key: string]: string | string[] } = {};
	array.forEach((elem) => {
		switch (elem.key) {
			case "Id":
				keys[formatRoute(elem.resourceType, true) + elem.key] =
					elem.value;
				break;
			case "Ids":
				keys[formatRoute(elem.resourceType, true) + elem.key] =
					Array.isArray(elem.value) ? elem.value : [elem.value];
				break;
			case "Object":
				keys[formatRoute(elem.resourceType, true)] = elem.value;
				break;
		}
	});
	return keys;
};
