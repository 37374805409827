import { Pattern } from "modules/types/pattern";
import { ResourceRealType } from "./../../types/global/index.d";
import { Group } from "modules/types/group";
import {
	deleteOntology,
	setClassProperties,
	updateOntology,
} from "store/ontology/actions";
import { deleteDataset, updateDataset } from "store/dataset/dataset-actions";
import { deletePattern, updatePattern } from "store/pattern/pattern-actions";
import {
	deleteDictionary,
	updateDictionary,
} from "store/dictionary/dictionary-actions";
import {
	updateCampaign,
	deleteCampaign,
} from "store/annotation-campaign/annotation-campaign-actions";
import { deleteGroup, updateGroup } from "store/group/group-actions";
import { ResourceType } from "modules/types/global";
import { deleteProject, updateProject } from "store/project/project-actions";
import { setUser } from "store/user/user-actions";

export const updateResource = (
	resourceType: ResourceType,
	resource: any,
	group?: Group
) => {
	switch (resourceType) {
		case "annotation-campaign":
			updateCampaign(resource);
			if (group) updateGroup(group);
			break;
		case "project":
			updateProject(resource);
			if (group) updateGroup(group);
			break;
		case "dictionary":
			updateDictionary(resource);
			if (group) updateGroup(group);
			break;
		case "pattern":
			updatePattern(resource);
			if (group) updateGroup(group);
			break;
		case "dataset":
			updateDataset(resource);
			if (group) updateGroup(group);
			break;
		case "ontology":
			updateOntology(resource);
			if (group) updateGroup(group);
			break;
		case "group":
			updateGroup(resource);
			break;
	}
};

/**
 * Update resources following if its one or many
 * @param response
 */
export const updateResponse = (response: any) => {
	Object.keys(response).forEach((key) => {
		if (Array.isArray(response[key]))
			response[key].forEach((resource: ResourceRealType) => {
				updateResponseIds(key, resource);
			});
		else updateResponseId(key, response[key]);
	});
};

/**
 * Update resources
 * @param resourceType
 * @param resource
 */
export const updateResponseIds = (resourceType: string, resource: any) => {
	switch (resourceType) {
		case "annotationCampaigns":
			updateCampaign(resource);
			break;
		case "projects":
			updateProject(resource);
			break;
		case "dictionaries":
			updateDictionary(resource);
			break;
		case "patterns":
			updatePattern(resource);
			break;
		case "dataSets":
			updateDataset(resource);
			break;
		case "knowledges":
			updateOntology(resource);
			break;
		case "group":
			updateGroup(resource);
			break;
		case "user":
			setUser(resource);
			break;
	}
};

/**
 * Update resource
 * @param resourceType
 * @param resource
 */
export const updateResponseId = (resourceType: string, resource: any) => {
	switch (resourceType) {
		case "annotationCampaign":
			updateCampaign(resource);
			break;
		case "project":
			updateProject(resource);
			break;
		case "dictionarie":
			updateDictionary(resource);
			break;
		case "pattern":
			updatePattern(resource);
			break;
		case "dataSet":
			updateDataset(resource);
			break;
		case "knowledge":
			updateOntology(resource);
			break;
		case "group":
			updateGroup(resource);
			break;
		case "user":
			setUser(resource);
			break;
	}
};

export const deleteResource = (
	resourceType: ResourceType,
	resource: ResourceRealType,
	result?: any
) => {
	switch (resourceType) {
		case "annotation-campaign":
			deleteCampaign(resource._id);
			break;
		case "project":
			deleteProject(resource._id);
			break;
		case "dictionary":
			deleteDictionary(resource._id);
			break;
		case "pattern":
			if (Array.isArray(result)) {
				const pattern = resource as Pattern;
				if (pattern.mode === "property") setClassProperties(result);
				deletePattern(pattern._id);
			}
			break;
		case "dataset":
			deleteDataset(resource._id);
			break;
		case "ontology":
			deleteOntology(resource._id);
			break;
		case "group":
			deleteGroup(resource._id);
			break;
	}
};
