/* eslint-disable no-unreachable */
import { AnyAction } from "@reduxjs/toolkit";
import { BooleanActionType } from "modules/types/global/store";
import { UserReducer } from "modules/types/user";
import { UserDataActionType } from "modules/types/user/store";
import * as methods from "./user-methods";

const initialState = {
	loggedIn: false,
	init: false,
	data: null,
} as UserReducer;

export const userReducer = (
	state = initialState,
	action: AnyAction
): UserReducer => {
	switch (action.type) {
		case "SET_USER":
			return methods.setUser(state, action as UserDataActionType);
		case "UPDATE_USER":
			return methods.updateUserGroups(state, action as any);
		case "DELETE_USER":
			return methods.deleteUser(state, action as UserDataActionType);

		case "SET_LOGGED_IN":
			return methods.setLoggedIn(state, action as BooleanActionType);

		default:
			break;
	}
	return state;
};
